:root {
    --background-clr: #fff;
    --accent-clr: #cdb5a2;
    --secondary-clr: #e4c4aa;
    --sandy-accent-color: #f0e3da;

    --main-font-clr-gray: #333333;
    --secondary-font-color-brown: #847061;
    --tertiary-font-color-light-gray: #888888;

    --main-font: adobe-garamond-pro, serif;
    --cormorant-font: 'Cormorant Garamond', serif;
    --fun-font: 'IM Fell DW Pica', serif;
    --subtext-font: 'Kristi', cursive;
    --secondary-fun-font: 'IM Fell English SC', serif;

    --line-height: 1.5;
    --main-letter-spacing: 0.05em;
    --secondary-letter-spacing: 0.1em;
}

body {
    min-height: 100%;
    font-family: var(--cormorant-font);
    font-weight: 300;
    font-size: 1.1rem;
    position: relative;
    background-color: var(--background-clr);
    border: none;
}

#header-div {
    max-width: 1500px;
    margin: auto;
}

#logo {
    display: block;
    width: 400px;
    height: 57px;
    margin: 0 auto 0px auto;
}

.header-logo-link {
    display: block; 
    margin: 100px auto 50px auto;
    width: 400px;
}

#header-div ul { 
    display: block;
    font-family: var(--cormorant-font);
    list-style: none;
    text-transform: uppercase;
    text-align: center;
}

.is-active {
    color: var(--accent-clr);
}


nav li {
    display: inline-block;
}

nav li:nth-child(n+2):nth-child(-n+5) {
    margin-left: 50px;
}

nav a {
    color: rgb(32, 32, 32);
    text-decoration: none;
    font-size: .95rem;  
    transition-duration: 250ms;
    
}

nav a:hover {
    color: var(--accent-clr);
}

label #button,
label #cancel {
    font-size: 1.5rem;
    float: right;
    margin: -100px 40px 0 0;
    cursor: pointer;
    display: none;
}

#check {
    display: none;
}

@media (max-width: 1200px) {
    #logo {
       width: 300px;
    }

    .header-logo-link {
        width: 300px;
    }
}

@media (max-width: 1000px) {
    #logo {
        width: 250px
    }

    .header-logo-link {
        width: 250px;
    }

    li a {
        font-size: .9rem;
    }
}

@media (max-width: 810px) {
    #logo {
        width: 250px;
        margin: -50px auto -40px auto;
    }

    .header-logo-link {
        width: 250px;
    }
    
    li a {
        font-size: .9rem;
    }

    label #button,
    label #cancel {
        margin: -60px 40px 0 0;
    }
}

@media(max-width: 500px) {
    label #button,
    label #cancel {
    margin: -46px 40px 0 0;
}
}


@media (max-width: 910px){
    label #button {
        display: block;
    }

    nav ul li:nth-child(1) {
        margin-left: 50px;
    }

    nav ul {
        position: fixed;
        background-color: rgb(248, 248, 248);
        width: 100%;
        height: 100vh;
        top: 140px;
        left: -100%;
        text-align: center;
        transition: all 750ms;
        z-index: 500;
    }

    nav ul li {
        display: block;
        top: 1px;
        margin-right: 30px;
        padding-top: 50px;
    }

    li a {
        font-size: 1.1rem;
    }

    #check:checked ~ ul {
        left: 0;
    }

    #check:checked ~ label #button {
        display: none;
    }

    #check:checked ~ label #cancel {
        display: block;
    }
}

@media (max-width: 500px) {
    #logo {
        width: 175px
    }
    li a {
        font-size: .9rem;
    }

    label #button {
        display: block;
        font-size: 20px;
        /* margin: -87px 40px 0 0; */
    }

    label #cancel {
        font-size: 20px;
        /* margin: -87px 40px 0 0; */
    }
    
}


  #thomas, #lori, #emilee {
    animation: fade 5000ms infinite;
  }

  #pics {
    display: flex;
    justify-content: center;
    margin: 100px 0px 50px 0;
  }

  #pics img {
    width: 274px;
    height: auto;
    padding: 0 5px 0 5px;
  }

  @media(max-width: 830px) {
    #pics img {
        width: 30%;
        padding: 0 5px 0 5px;
      }
  }

  /* #stationary {
    animation: fade2 5000ms linear;
  } */

  @keyframes fade {
    0% { opacity: 0 }
    6% { opacity: 0 }
    30% { opacity: 1 }
    70% { opacity: 1 }
    /* 85% { opacity: 0 } */
    100% { opacity: 0 }
  }

  @keyframes fade2 {
    0% { opacity: 0 }
    6% { opacity: 0 }
    10% { opacity: 1 }
  }

  #home-about-section {
    background-color: var(--sandy-accent-color);
    text-align: center;
  }

  #home-about-section h1 {
    padding-top: 50px;
    letter-spacing: var(--secondary-letter-spacing);
    font-size: 25px;
    color: var(--main-font-clr-gray);
  }

  #about-head-subtext {
    color: var(--secondary-font-color-brown);
    letter-spacing: var(--secondary-letter-spacing);
    font-size: 14px;
    padding: 20px 0 20px 0;
  }

  #nyss-home-about {
    width: 274px;
    height: auto;
    border-radius: 15px;
  }

  .home-about-text {
    margin-top: 65px;
    width: 244px;
    text-align: justify;
    font-weight: 100;
    line-height: var(--line-height);
    letter-spacing: .05em;
    font-size: 14px;
    padding-bottom: 20px;
  }

  .more-about-me {
    letter-spacing: var(--secondary-letter-spacing);
    font-size: 13px;
    color: var(--main-font-clr-gray);
    cursor: pointer;
    text-decoration: none;
  }

  .more-about-me:hover {
    color: var(--accent-clr);
  }

  .book-now {
    color: var(--secondary-font-color-brown);
    font-size: 18px;
    letter-spacing: var(--secondary-letter-spacing);
    padding: 15px 0 15px 0;
    cursor: pointer;
    text-decoration: none;
    transition: 250ms;
  }

  .book-now:hover {
    transform: translateY(-5px) ;
  }

  .main-font {
    font-family: var(--cormorant-font);
    font-weight: 100;
  }

  .accent-font {
    font-family: var(--fun-font);
    font-weight: 100;
  }

  #home-about-split {
    display: flex;
    justify-content: center;
  } 

  #home-about-split div {
    margin: 0 20px 0 20px;
  }

  #home-about-split div:nth-child(2) {
    display: flex;
    flex-direction: column;
  }

  @media(max-width: 830px) {
    #home-about-split {
        display: flex;
        justify-content: center;
        flex-direction: column-reverse;
      }

      #home-about-split img {
        margin: auto;
      }

      .home-about-text {
        display: block;
        margin: auto;
      }

      .more-about-me {
        display: block;
        margin-bottom: 15px;
      }

      .book-now {
        padding-bottom: 30px;
      }

      #home-about-section h1 {
        font-size: 20px;
      }
    
      #about-head-subtext {
        font-size: 11px;
      }
  }

  @media(max-width: 501px) {
    #home-about-split img {
        width: 225px;
    }

    .home-about-text {
        width: 200px;
        font-size: 13px;
      }

      #home-about-section h1 {
        font-size: 17px;
      }
    
      #about-head-subtext {
        font-size: 10px;
      }

      #rotating-review-section {
        font-size: 15px;
        padding: 0 10px 0 10px;
      }
  }

  #quote-super-section {
    background-color: #f7f7f5;
    padding: 5px 0 5px 0;
  }

  #quote-section {
    background-color: #f7f7f5;
    border-top: 1px rgb(183, 183, 183) solid;
    border-bottom: 1px rgb(183, 183, 183) solid;
    text-transform: uppercase;
    line-height: var(--line-height);
    letter-spacing: var(--secondary-letter-spacing);
    color: var(--secondary-font-color-brown);
    font-size: 12px;
    text-align: center;
    padding: 30px 0 30px 0;
    font-family: var(--fun-font);
  }

.back-img {
    background-image: url("./Data/Home/bride and groom flowwer detail wedding toccoa falls.jpg");
    min-height: 100%;
    height: 400px;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    background-size: 125%;
}

@media(max-width: 1000px) {
    .back-img {
        display: none;
    }
}

#polaroid-section {
    display: flex;
    justify-content: center;
}

.polaroid-photo-border {
    border: 1px rgb(183, 183, 183) solid;
    width: 274px;
    padding: 18px 18px 20px 18px;
    text-align: center;
    margin: 90px 5px 0 5px;
}

.polaroid-photo {
    width: 238px;
    height: 333px;
    object-fit: cover;
    opacity: 1;
    transition: opacity .3s;
}

#polaroid-section div:nth-child(1) img {
    object-position: 0% 90%;
}

@media(max-width: 850px) {
    #polaroid-section {
        flex-direction: column;
        justify-content: center;
    }

    .polaroid-photo-border {
        display: block;
        margin: 50px auto 10px auto;
    }
}

.polaroid-photo:hover {
    opacity: 0.5;
    cursor: pointer;
}

.polaroid-photo-border p, .polaroid-photo-border-about p {
    font-family: var(--fun-font);
    font-size: 20px;
    letter-spacing: var(--main-letter-spacing);
    color: var(--secondary-font-color-brown);
    margin-top: 15px;
}

.polaroid-photo-border a:link {
    text-decoration: none;
}

#rotating-review-section {
    background-color: var(--sandy-accent-color);
    font-family: var(--cormorant-font);
    text-align: center;
    letter-spacing: var(--main-letter-spacing);
    margin-top: 50px;
    padding: 70px 0 70px 0;
    color: var(--main-font-clr-gray);
    text-transform: uppercase;
}

#rotating-review-section p {
    font-style: italic;
}

@media (max-width: 650px) {
    .back-img {
        background-image: none;
    }

    .back-img:before {
        content: "";
        display: block;
        left: 0;
        top: 0;
        width: 100%;
        height: 500px;
        z-index: -10;
        /* background: url("./Data/Home/suze-trey-gray.jpeg") no-repeat center center; */
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        background-attachment: fixed;
    }
    
}

#secondlogo {
    text-align: center;
    text-decoration: none;
}

.built {
    text-align: center;
    text-decoration: none;
    color: rgb(32, 32, 32);
    font-size: .8rem;
    transition-duration: 250ms;
}

.built:hover {
    color: var(--accent-clr);
}

#invest-in-the-moments {
    max-width: 1500px;
    margin: auto;
    display: block;
    margin-top: 100px;
    text-align: center;
}

#investment-main-photo {
    display: block;
    margin: auto;
    width: 100%;
    object-fit: cover;
    object-position: 70% 80%;
    border-radius: 10px;
}

@media(max-width: 1500px) {
    #investment-main-photo {
        border-radius: 0px;
    }
}

#invest-in-the-moments p:nth-child(2) {
    margin-top: 30px;
    font-size: 22px;
    letter-spacing: var(--secondary-letter-spacing);
    color: var(--main-font-clr-gray);
    font-family: var(--cormorant-font);
}

#invest-in-the-moments p:nth-child(3) {
    margin-top: 25px;
    font-size: 10.5px;
    letter-spacing: var(--secondary-letter-spacing);
    color: var(--secondary-font-color-brown);
    font-family: var(--fun-font);
    text-transform: uppercase;
}

#invest-in-the-moments p:nth-child(4) {
    display: block;
    margin: 40px auto auto auto;
    width: 433px;
    font-size: 13px;
    letter-spacing: 0.03em;
    color: #333333;
    font-family: var(--cormorant-font);
    line-height: var(--line-height);
    text-align: justify;
}

#investment-review-strip {
    margin-top: 60px;
    width: 100%;
    background-color: var(--sandy-accent-color);
    text-align: center;
    padding: 50px 0 50px 0;
}

#investment-review-strip p {
    width: 534px;
    font-size: 18px;
    font-family: var(--cormorant-font);
    color: var(--main-font-clr-gray);
    line-height: var(--line-height);
    letter-spacing: var(--secondary-letter-spacing);
    margin: auto;
    font-style: italic;
}

#invest-memories {
    text-align: center;
}

#invest-memories h1 {
    color: var(--secondary-font-color-brown);
    font-family: var(--fun-font);
    font-size: 21px;
    letter-spacing: var(--secondary-letter-spacing);
    margin: 40px 0 15px 0;
}

#invest-memories hr:nth-child(2) {
    margin: auto auto 15px auto;
    width: 69px;
    background-color: #e0d3ca;
    height: 1px;
    border: none;
}

#pricing {
    color: var(--tertiary-font-color-light-gray);
    font-family: var(--cormorant-font);
    font-size: 14px;
    letter-spacing: var(--secondary-letter-spacing);
    font-style: italic;
    margin-bottom: 15px;
}

.polaroid-photo-border-investment {
    border: 1px rgb(183, 183, 183) solid;
    width: 274px;
    padding: 15px 5px 5px 5px;
    text-align: center;
    background-color: white;
}

.polaroid-photo-investment {
    width: 90.4%;
    max-width: 238px;
    object-fit: cover;
    opacity: 1;
    transition: opacity 0.3s;
    cursor: pointer;
}

.polaroid-photo-investment:hover {
    opacity: .5;
}

.polaroid-photo-border-investment a:link:nth-child(2) {
    text-decoration: none;
}

.polaroid-photo-border-investment p {
    color: var(--main-font-clr-gray);
    margin-top: 5px;
    margin-bottom: 5px;
    font-family: var(--cormorant-font);
    font-size: 18px;
    letter-spacing: var(--main-letter-spacing);
}

.polaroid-photo-border-investment p:hover {
    color: var(--accent-clr);
}

.polaroid-photo-border-investment p:nth-child(3) {
    font-size: 12px;
    letter-spacing: var(--main-letter-spacing); 
    /* font-family: var(--fun-font); */
    color: var(--secondary-font-color-brown);
    margin-bottom: 5px;
}

#pricing-polaroid-section {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

#pricing-polaroid-section div:nth-child(1) {
    margin-right: 5px;
}

#pricing-polaroid-section div:nth-child(2) {
    margin-left: 5px;
}

#investment-inquire-section {
    width: 100%;
    background-color: var(--sandy-accent-color);
    margin-top: 50px;
}

#ivestment-inquire-main-text {
    padding: 50px 0 25px 0;
    width: 318px;
    font-family: var(--secondary-fun-font);
    color: var(--secondary-font-color-brown);
    font-size: 19px;
    /* text-transform: uppercase; */
    letter-spacing: var(--main-letter-spacing) ;
    line-height: var(--line-height);
    font-weight: 400;
    margin: 50px auto auto auto;
    text-align: center;
}



#investment-inquire-section button {
    display: block;
    border: none;
    width: 156px;
    height: 58px;
    background-color: #e0d3ca;
    border-top-left-radius: 29px;
    border-bottom-right-radius: 29px;
    font-style: italic;
    font-size: 20px;
    margin: auto;
    font-family: var(--cormorant-font);
    cursor: pointer;
    transition: 250ms;
    color: #000;
}


#investment-inquire-section button:hover {
    transform: translateY(-5px);
}

#investment-inquire-section a:link {
    text-decoration: none;
}

#browse-galleries {
    font-size: 14px;
    letter-spacing: var(--secondary-letter-spacing);
    color: var(--tertiary-font-color-light-gray);
    text-align: center;
    padding: 25px 0 25px 0;
    cursor: pointer;
}

#browse-galleries:hover {
    color: var(--accent-clr)

}

@media (max-width: 575px) {
    
    #investment-review-strip p {
        width: 420px;
        font-size: 14px;
    }

    .polaroid-photo-border-investment {
        border: 1px rgb(183, 183, 183) solid;
        width: 48%;
    }
    
    .polaroid-photo-investment {
        width: 90.4%;
    }
}

@media (max-width: 425px) {
    .polaroid-photo-border-investment p {
        font-size: 15px;
    }
    
    .polaroid-photo-border-investment p:nth-child(3) {
        font-size: 9px;
    }

    #invest-in-the-moments p:nth-child(2) {
        margin-top: 27px;
        font-size: 20px;
    }
    
    #invest-in-the-moments p:nth-child(3) {
        margin-top: 22px;
        font-size: 8.5px;
    }
    
    #invest-in-the-moments p:nth-child(4) {
        margin: 37px auto auto auto;
        width: 373px;
        font-size: 11px;
    }

    #investment-review-strip p {
        width: 330px;
        font-size: 11px;
    }

    #ivestment-inquire-main-text {
        width: 250px;
        font-size: 15px;
    }
    
    #investment-inquire-section button {
        width: 136px;
        height: 50px;
        font-size: 15px;
    }
    
    #browse-galleries {
        font-size: 13px;
    }
}

@media (max-width: 400px) {
    #invest-in-the-moments p:nth-child(2) {
        margin-top: 27px;
        font-size: 14px;
    }
    
    #invest-in-the-moments p:nth-child(3) {
        margin-top: 16px;
        font-size: 8.5px;
    }
    
    #invest-in-the-moments p:nth-child(4) {
        margin: 33px auto auto auto;
        width: 305px;
        font-size: 9px;
    }
}



#buttonid {
    text-align: center;
}

#inquireButton:hover, #form-button:hover {
    background-color: var(--background-clr);
    color: var(--accent-clr);
    border-color: var(--accent-clr);
    border: 1px solid var(--accent-clr);
    cursor: pointer;
}

#form-button {
    background-color: var(--accent-clr);
    border: none;
    color: var(--background-clr);
    width: 100px;
    height: 30px;
    font-size: .9rem;
    text-decoration: none;
    border-color: var(--background-clr);
    font-family: var(--main-font);
    transition-duration: 250ms;
}


#inquireButton a {
    text-decoration: none;
    font-weight: 500px;
    font-size: 1.1rem;
    text-transform: uppercase;
}

#aslogo {
    width: 300px;
    height: 150px;
    object-fit: cover;
    margin-top: 50px;
    margin-bottom: 60px;
    border-bottom: 1px #000 solid;
}


/*ABOUT ME STYLING*/ 

#about-main {
    display: flex;
    flex-direction: row;
    max-width: 1500px;
    margin: auto;
    justify-content: center;
    margin-top: 150px;
}

#main-header-about, #main-header-investment {
    display: none;
}

#about-main img, #philosophy-section div {
    margin-right: 27.5px;
}

#about-main div, .camera-photo-show {
    margin-left: 27.5px;
}

#about-main-text:nth-child(3) {
    text-align: center;
}

#about-main-text h2 {
    margin-top: 40px;
}

#about-main-text p:nth-child(2)  {
    margin-top: 30px;
}



#about-main-text > p, #philosophy-section p:nth-child(n+3):nth-child(-n+5) {
    padding: 9px 0 9px 0;
}

#about-main-text p {
    width: 307px;
    text-align: justify;
    line-height: var(--line-height);
    font-size: 13px;
    letter-spacing: .05em;
    font-family: var(--cormorant-font);
    color: var(--main-font-clr-gray);
}

#about-main-text h2, #philosophy-section h2 {
    text-align: center;
    color: var(--secondary-font-color-brown);
    font-family: var(--fun-font);
    font-size: 20px;
    letter-spacing: var(--secondary-letter-spacing);
}

#about-main-text p:nth-child(4) {
    text-align: center;
    color: var(--secondary-font-color-brown);
    font-size: 14px;
    letter-spacing: var(--secondary-letter-spacing);
    font-family: var(--fun-font);
}

#about-main img {
    width: 274px;
    border-radius: 10px;
}

@media(max-width: 810px) {
    #about-main {
        flex-direction: column;
    }

    #about-main img {
        margin: auto auto 25px auto;
    }

    #about-main-text {
        margin-bottom: -40px;
    }

    #about-main-text p {
        margin: auto;
    }

    #about-main div {
        margin-left: 0;
    }
}

#about-interests-section {
    margin-top: 75px;
    display: flex;
    justify-content: center;
    background-color: var(--sandy-accent-color);
}

#about-interests-section div {
    margin: 15px 5px 15px 5px;
}


.polaroid-photo-border-about {
    border: 1px rgb(183, 183, 183) solid;
    width: 210px;
    padding: 10px 5px 5px 5px;
    text-align: center;
    background-color: white;
}

.polaroid-photo-about {
    width: 93.98%;
    max-width: 188px;
    /* height: 249px; */
    object-fit: cover;
}

.polaroid-photo-border-about p {
    font-family: var(--fun-font);
    font-size: 15px;
    letter-spacing: var(--main-letter-spacing);
    color: var(--secondary-font-color-brown);
    margin-top: 5px;
}

#up-and-down {
    padding: 150px 0 0 20px;
    margin-right: -70px;
    display: flex;
    flex-direction: column;
}

#up-and-down:hover {
    cursor: pointer;
}

@media(max-width: 810px ) {
    .polaroid-photo-about {
        width: 93.98%;
        object-fit: cover;
    }

    .polaroid-photo-border-about {
        width: 26.5%;
    }

    #up-and-down {
        position: absolute;
        padding: 0 7px 0 0;
        margin-right: 0;
        top: 40.2%;
        right:0;
        display: flex;
        flex-direction: column;
        font-size: 7px;
    }
}

@media(max-width: 810px ) {
    #up-and-down {
        padding: 0 20px 0 0;
        
    }

    .up-and-down-font {
        font-size: 25px;
    }
}

@media(max-width: 750px ) {
    #up-and-down {
        padding: 0 20px 0 0;
        top: 40.2%;
    }

    .up-and-down-font {
        font-size: 25px;
    }
}

@media(max-width: 615px ) {
    #up-and-down {
        padding: 0 15px 0 0;
        top: 40.6%;
    }

    .up-and-down-font {
        font-size: 25px;
    }
}

@media(max-width: 500px ) {
    #up-and-down {
        padding: 0 8px 0 0;
        top: 41%;
    }

    .up-and-down-font {
        font-size: 22px;
    }

    .polaroid-photo-border-about p {
        font-size: 13px;
    }
}

@media(max-width: 400px) {
    #up-and-down {
        padding: 0 3px 0 0;
        top: 41%;
    }

    .up-and-down-font {
        font-size: 21px;
    }

    .polaroid-photo-border-about p {
        font-size: 12px;
    }
}

#philosophy-section {
    margin-top: 100px;
    max-width: 1500px;
    margin: 100px auto auto auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.camera-photo-show {
    width: 274px;
    border-radius: 10px;
}

.camera-photo-hide {
    display: none;
}

#philosophy-section div {
    width: 321px;
    font-size: 13px;
}

#philosophy-section p:nth-child(n+2):nth-child(-n+3) {
   text-align: center;
   font-size: 13px;
   font-family: var(--cormorant-font);
   color: var(--tertiary-font-color-light-gray);
   font-style: italic;
   width: 238px;
   letter-spacing: var(--secondary-letter-spacing);
   margin-left: 35px;
}

#philosophy-section p:nth-child(2) {
    margin: 10px 0 -5px 0;
}

#philosophy-section p:nth-child(6) {
    margin-bottom: 15px;
}

#philosophy-section p:nth-child(5), #philosophy-section p:nth-child(7) {
    color: var(--main-font-clr-gray);
    font-size: 13px;
    text-align: justify;
}


#philosophy-section p:nth-child(n+5):nth-child(-n+9) {
    width: 321px;
    line-height: var(--line-height);
    letter-spacing: .05em;
    font-family: var(--cormorant-font);
}

#philosophy-section p:nth-child(8) {
    padding: 9px 0 0px 0;
}

#philosophy-section p:nth-child(8), #philosophy-section p:nth-child(6) {
    color: var(--secondary-font-color-brown);
    font-family: var(--fun-font);
    font-size: 15px;
    text-align: center;
}

@media(max-width: 810px) {
    #philosophy-section {
        display: block;
    }
    
    
    .camera-photo-show {
        display: block;
        width: 274px;
        border-radius: 10px;
        margin: 20px auto 50px auto;
    }
    
    .camera-photo-hide {
        display: none;
    }
    
    #philosophy-section div {
        width: 321px;
        font-size: 13px;
        margin: -40px auto auto auto;
    }
    
    #philosophy-section p:nth-child(n+2):nth-child(-n+3) {
       text-align: center;
       font-size: 13px;
       font-family: var(--cormorant-font);
       color: var(--tertiary-font-color-light-gray);
       font-style: italic;
       width: 238px;
       letter-spacing: var(--secondary-letter-spacing);
       margin-left: 35px;
    }
}

#about-nav-buttons {
    display: flex;
    max-width: 1500px;
    justify-content: center;
    margin-top: 45px;
    margin: 45px auto auto auto;
}

#about-nav-buttons div {
    width: 146px;
    display: flex;
    flex-direction: column;
}

#about-nav-buttons div button {
    transition: 250ms;
}

#about-nav-buttons div button:hover {
    transform: translateY(-5px);
}

.down-arrow-nav {
    margin-left: 65px;
    margin-bottom: 10px;
}

#about-nav-buttons button {
    width: 146px;
    height: 40px;
    border: none;
    border-radius: 10px;
    background-color: #f4ebe4;
    font-family: var(--fun-font);
    font-size: 18px;
    color: var(--secondary-font-color-brown);
    letter-spacing: var(--secondary-letter-spacing);
    cursor: pointer;
}

#about-nav-buttons div:nth-child(2) {
    margin: 0 100px 0 100px;
} 

@media(max-width: 810px) {
    #about-nav-buttons div:nth-child(2) {
        margin: 0 60px 0 60px;
    } 

    .down-arrow-nav {
        margin-left: 50px;
        margin-bottom: 10px;
    }

    #about-nav-buttons button {
        width: 116px;
        height: 40px;
        font-size: 15px;
    }

    #about-nav-buttons div {
        width: 116px;
    }
}

@media(max-width: 615px) {
    #about-nav-buttons div:nth-child(2) {
        margin: 0 30px 0 30px;
    } 

    .down-arrow-nav {
        margin-left: 40px;
        margin-bottom: 10px;
    }

    #about-nav-buttons button {
        width: 96px;
        height: 30px;
        font-size: 15px;
    }

    #about-nav-buttons div {
        width: 96px;
    }
}

@media(max-width: 355px) {
    #about-nav-buttons div:nth-child(2) {
        margin: 0 27px 0 27px;
    } 

    .down-arrow-nav {
        margin-left: 35px;
        margin-bottom: 10px;
    }

    #about-nav-buttons button {
        width: 86px;
        height: 27px;
        font-size: 15px;
    }

    #about-nav-buttons div {
        width: 86px;
    }
}

/*investment*/

footer, #footer {
    max-width: 1500px;
    margin: auto;
    text-align: center;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 150px;
}

@media(max-width: 810px) {
    footer, #footer {
        margin-top: 100px;
    }
}


footer a {
    text-decoration: none;
    color: var(--secondary-font-color-brown);
    transition-duration: 250ms;
}

footer a:hover {
    color: var(--accent-clr);
}


#about-pic {
    max-width: 600px;
    margin: 0px 0px 0px 500px;

} 

.anyssa-sands {
    color:var(--accent-clr);
    font-size: 3rem;
    margin-bottom: 25px;
    font-family: 'Nothing You Could Do', cursive;
}

.anyssa-sands2 {
    color:var(--accent-clr);
    font-size: 3rem;
    margin-bottom: 0px;
    font-family: 'Nothing You Could Do', cursive;
}

#fun-things-background{
    background-color: #d6d9de;
}

#review-background {
    background-color: #e9e9e9;
    /* background-image: url("https://www.transparenttextures.com/patterns/black-thread-light.png"); */
    margin: 100px 0px 50px 0px;
    position: relative;
}

#insta-photo-container {
    margin-left: -20px;
}

#insta-photo-container img:nth-child(1) {
    position: relative;
    object-position: 0% 50%;
}

#insta-photo-container img:nth-child(3) {
    position: relative;
    z-index: -1;
    object-position: 0% 70%;
}

#insta-photo-container img:nth-child(4) {
    position: relative;
    z-index: -2;
}

#insta-photo-container img:nth-child(5) {
    position: relative;
    z-index: -3;
    object-position: 0% 0%;
}

.insta-photos {
    width: 19%;
    height: 200px;
    max-width: 200px;
    object-fit: cover;
    margin: 0px -30px 50px 10px;

}

.odd-insta {
    width: 22%;
    max-width: 220px;
    border: 12px solid white;
    margin-bottom: 100px;
}

@media(max-width: 991px) {
    .insta-photos {
        width: 19%;
        object-fit: cover;
        margin: 0px -30px 50px 10px;
    }
    
    .odd-insta {
        width: 22%;
        object-fit: cover;
        border: 12px solid white;
        margin-bottom: 100px;
    }
}

@media(max-width: 750px) {
    .insta-photos {

        margin: 0px -30px 50px 10px;
    }
    
    .odd-insta {
        margin-bottom: 75px;
        border: 10px solid white;
    }
}

@media(max-width: 550px) {
    
    .odd-insta {
        margin-bottom: 65px;
        border: 7px solid white;
    }
}

@media(max-width: 400px) {
    
    .odd-insta {
        margin-bottom: 65px;
        border: 5px solid white;
    }
}

#follow-me {
    font-family: "Herr Von Muellerhoff";
    font-size: 80px;
    margin: 100px 0px 15px 0px;
    font-weight: 400;
    color: #e7e7e7;
    position: relative;
}


#follow-me a { 
    font-family: var(--main-font);
    text-decoration: none;
    font-weight: 400;
    color: #000000;
    position: absolute;
    margin-top: 80px;
    margin-left: -100px;
}

#follow-along {
    color: var(--secondary-font-color-brown);
    letter-spacing: var(--secondary-letter-spacing);
    font-size: 13px;
}

#follow-me a:hover {
    color: var(--accent-clr);
}

#insta-icon:hover {
    cursor: pointer;
}

#instagram{
    text-align: center;
}

@media (max-width: 810px) {
    #follow-along:after {
        content: "\a";
        white-space: pre;
    }
}

#instagram div p {
    margin-bottom: 40px;
}

#inquire-main-section {
    margin-top: 150px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

#inquire-main-section img {
    width: 326px; 
}

#inquire-main-section div h1 {
   margin-top: 100px;
   color: var(--secondary-font-color-brown);
   font-family: var(--secondary-fun-font);
   font-size: 18px;
   letter-spacing: var(--secondary-letter-spacing);
   margin-left: -35px;
}

#inquire-main-section div h2 {
    color: #ab9d93;
    font-family: var(--cormorant-font);
    font-size: 14px;
    letter-spacing: var(--secondary-letter-spacing);
    margin-left: -5px;
 }

 #inquire-main-section div p:nth-child(3) {
    margin-top: 30px;
    color: var(--tertiary-font-color-light-gray);
    font-family: var(--fun-font);
    font-size: 13px;
    letter-spacing: var(--main-letter-spacing);
    width: 216px;
    text-align: justify;
 }

 #inquire-main-section div p:nth-child(4) {
    margin-top: 20px;
    color: var(--tertiary-font-color-light-gray);
    font-family: var(--fun-font);
    font-size: 13px;
    letter-spacing: var(--main-letter-spacing);
    width: 216px;
    text-align: right;
 }

 #inquire-main-section div p:nth-child(5) {
    margin: 25px 0 0 -15px;
    color: var(--tertiary-font-color-light-gray);
    font-family: var(--fun-font);
    font-size: 11px;
    letter-spacing: var(--main-letter-spacing);
    width: 216px;
    text-align: left;
    font-style: italic;
 }

 .inquire-form {
    margin: 0 15px 0 15px;
 }

 @media (max-width: 550px) {
    #inquire-main-section img {
        width: 255px;
    }

    #inquire-main-section div h1 {
        margin-top: 50px;
        font-size: 16px;
        margin-left: -45px;
     }
     
     #inquire-main-section div h2 {
         font-size: 12px;
         margin-left: -15px;
      }
     
      #inquire-main-section div p:nth-child(3) {
         margin-top: 30px;
         color: var(--tertiary-font-color-light-gray);
         font-family: var(--fun-font);
         font-size: 11px;
         letter-spacing: var(--main-letter-spacing);
         width: 216px;
         text-align: justify;
      }
     
      #inquire-main-section div p:nth-child(4) {
         margin-top: 20px;
         color: var(--tertiary-font-color-light-gray);
         font-family: var(--fun-font);
         font-size: 12px;
         letter-spacing: var(--main-letter-spacing);
         width: 216px;
         text-align: right;
      }
     
      #inquire-main-section div p:nth-child(5) {
         font-size: 9px;
      }
 }

 @media (max-width: 500px) {
    #inquire-main-section img {
        margin: auto auto 25px auto;
    }

    #inquire-main-section {
        margin-top: 150px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    #inquire-main-section div h1 {
        margin-top: 100px;
        color: var(--secondary-font-color-brown);
        font-family: var(--secondary-fun-font);
        font-size: 18px;
        letter-spacing: var(--secondary-letter-spacing);
        margin: auto;
     }
     
     #inquire-main-section div h2 {
         font-size: 14px;
         margin: auto auto 25px auto;
         text-align: center;
      }
     
      #inquire-main-section div p:nth-child(3) {
         margin-top: 30px;
         font-size: 13px;
         width: 216px;
         text-align: justify;
         margin: auto;
      }
     
      #inquire-main-section div p:nth-child(4) {
         font-size: 13px;
         text-align: right;
         display: block;
         margin: 20px auto 25px auto;
      }
     
      #inquire-main-section div p:nth-child(5) {
         margin: auto;
         font-size: 11px;
         text-align: center;
      }
 }

 h1 {
    font-size: 20px;
    color: var(--secondary-font-color-brown);
    font-family: var(--cormorant-font);
    text-align: center;
    letter-spacing: var(--secondary-letter-spacing);
 }

 #portfolio-main {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    width: 750px;
    margin: 100px auto auto auto;
    padding: 15px 0 15px 0;
    background-color: var(--sandy-accent-color);
 }

 #portfolio-border {
    border: hsla(26, 15%, 45%, 0.473) .5px solid;
    /* padding: 20px; */
    padding: 45px 0 75px 0;
    width: 720px;
    margin: auto;
 }

 #polaroid-section-portfolio {
    display: flex;
    justify-content: center;
}

#polaroid-section-portfolio div {
    margin: 60px 10px 0 10px;
}

.polaroid-photo-border-portfolio {
    border: hsla(26, 15%, 45%, 0.473) .5px solid;
    width: 274px;
    padding: 18px 18px 20px 18px;
    text-align: center;
    background-color: white;
}

.polaroid-photo-portfolio {
    width: 98.5%;
    max-width: 238px;
    object-fit: cover;
    opacity: 1;
    transition: opacity 0.3s;
}

.polaroid-photo-portfolio:hover {
    opacity: 0.5;
}

.polaroid-photo-border-portfolio p {
    font-family: var(--fun-font);
    font-size: 20px;
    letter-spacing: var(--main-letter-spacing);
    color: var(--secondary-font-color-brown);
    margin-top: 15px;
}

@media (max-width: 775px) {
    #portfolio-main {
        width: 90%;
     }

     #portfolio-border {
        border: hsla(26, 15%, 45%, 0.473) .5px solid;
        /* padding: 20px; */
        padding: 45px 0 75px 0;
        width: 95%;
        margin: auto;
     }

     #polaroid-section-portfolio div {
        margin: 60px 10px 0 10px;
    }
    
    .polaroid-photo-border-portfolio {
        border: hsla(26, 15%, 45%, 0.473) .5px solid;
        width: 44%;
        padding: 18px 18px 17px 18px;
    }
    
    .polaroid-photo-portfolio {
        width: 98.5%;
    }

 }

 @media (max-width: 650px) {
    .polaroid-photo-border-portfolio p {
        font-size: 17px;
    }

    .polaroid-photo-border-portfolio {
        border: hsla(26, 15%, 45%, 0.473) .5px solid;
        width: 44%;
        padding: 18px 18px 15px 18px;
    }
 }

 @media (max-width: 575px) {
    .polaroid-photo-border-portfolio p {
        margin-top: 12px;
        font-size: 14px;
    }

    .polaroid-photo-border-portfolio {
        border: hsla(26, 15%, 45%, 0.473) .5px solid;
        width: 44%;
        padding: 15px 15px 12px 15px;
    }
 }

 @media (max-width: 475px) {
    .polaroid-photo-border-portfolio p {
        margin-top: 10px;
        font-size: 14px;
    }

    .polaroid-photo-border-portfolio {
        border: hsla(26, 15%, 45%, 0.473) .5px solid;
        width: 44%;
        padding: 13px 13px 10px 13px;
    }
 }

 @media (max-width: 465px) {
    .polaroid-photo-border-portfolio p {
        margin-top: 9px;
        font-size: 12px;
    }

    .polaroid-photo-border-portfolio {
        border: hsla(26, 15%, 45%, 0.473) .5px solid;
        width: 44%;
        padding: 13px 13px 10px 13px;
    }
 }

 @media (max-width: 400px) {
    .polaroid-photo-border-portfolio p {
        margin-top: 7px;
        font-size: 11px;
    }

    .polaroid-photo-border-portfolio {
        border: hsla(26, 15%, 45%, 0.473) .5px solid;
        width: 44%;
        padding: 13px 13px 7px 13px;
    }
 }

 @media (max-width: 380px) {
    .polaroid-photo-border-portfolio p {
        margin-top: 3px;
        font-size: 10.5px;
    }

    .polaroid-photo-border-portfolio {
        border: hsla(26, 15%, 45%, 0.473) .5px solid;
        width: 44%;
        padding: 12px 12px 5px 12px;
    }
 }

#main-wedding-photos {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 100px auto auto auto;
    padding: 17px 0 17px 0;
    /* border: var(--secondary-font-color-brown) 1px solid; */
    width: 750px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

#wedding-border {
    margin: auto;
    border: hsla(26, 15%, 45%, 0.473) .5px solid;
    /* padding: 20px; */
    padding: 45px 0 45px 0;
    width: 720px;
}

#wedding-inner-border {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

#top-photo-container {
    max-width: 614px;
    margin: auto;
}

.wedding-photo-top {
    width: 614px;
    height: auto;
    margin: auto;
    cursor: pointer;
}

#wedding-column-1, #wedding-column-2 {
    display: flex;
    flex-direction: column;
}

.wedding-photos {
    width: 300px;
    height: auto;
    margin: 7px;
    cursor: pointer;
}

@media(max-width: 830px) {
    .wedding-photo-top {
        width: 100%;
    }

    #top-photo-container {
        max-width: 86.5%;
    }

    #main-wedding-photos {
        width: 90.5%;
        padding: 2.1% 0 2.1% 0;
    }
    
    #wedding-border {
        width: 96%;
        padding: 6% 0 6%  0;
    }

    .wedding-photos {
        width: 84.5%;
        margin: 2%;
        cursor: pointer;
    }

    #wedding-column-1 .wedding-photos {
        margin-left: auto;
    }

    #wedding-column-2 .wedding-photos {
        margin-right: auto;
    }
}

.modal-show {
    display: block; 
    position: fixed; 
    z-index: 1; 
    padding: 50px 0 50px 0; 
    left: 0;
    top: 0;
    width: 100%; 
    height: 100%; 
    overflow: auto; 
    background-color: rgb(255, 255, 255); 
    background-color: rgba(255, 255, 255, 0.9); 
  }

  .modal-hide {
    display: none; 
    position: fixed; 
    z-index: 1; 
    padding-top: 100px; 
    left: 0;
    top: 0;
    width: 100%; 
    height: 100%; 
    overflow: auto; 
    background-color: rgb(0,0,0); 
    background-color: rgba(0,0,0,0.9); 
  }

  .modal-content {
    position: absolute;
    margin: auto;
    display: block;
    object-fit: contain;
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    padding: 50px 0 50px 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .close {
    position: absolute;
    z-index: 1;
    top: 10px;
    right: 35px;
    color: #969696;
    font-size: 40px;
    font-weight: bold;
    transition: 0.3s;
  }

  .wedding-nav-right {
    cursor: pointer;
    position: absolute;
    top: 46.5%;
    right: 1%;
    color: #f1f1f1;
    font-size: 65px;
    font-weight: bold;
    transition: 0.3s;
    z-index: 1;
  }

  .wedding-nav-left {
    cursor: pointer;
    position: absolute;
    top: 46.5%;
    left: 1%;
    color: #f1f1f1;
    font-size: 65px;
    font-weight: bold;
    transition: 0.3s;
  }
  
  .close:hover,
  .close:focus {
    color: #bbb;
    text-decoration: none;
    cursor: pointer;
  }